// Dependencies
import React from "react"
// Components
import Layout from "../components/Layout"
import Store from "../components/Store"

const NotFoundPage = () => (
  <Store>
    <Layout title="404: Not found">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  </Store>
)

export default NotFoundPage
